<!-- 优惠劵管理页面 -->
<template>
  <div class="couponManagement">
    <div class="head-area">
      <Button type="primary" @click="add">添加优惠劵</Button>
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" :height="tableHeight">
        <template #days="{ row }">
          <span>{{row.restrictDateType=='1'?row.days+'天':row.startDate+'~'+row.endDate}}</span>
        </template>
        <template
          #restrictCouponNum="{ row }"
        >{{row.restrictCouponNum==0?'不限制':row.restrictCouponNum}}</template>
        <template #action="{ row }">
          <a href="###" @click="editDetial(row)" style="margin-right:10px">编辑</a>
          <a href="###" @click="del(row)">删除</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <Drawer :title="titleName" v-model="modelShow" width="800" :mask-closable="false">
      <addBox v-if="modelShow" ref="modelShow" :titleName="titleName" :currentData="currentData" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import addBox from './components/addBox.vue'
import { getCouponList, addCoupon, delCoupon } from '@/api/couponManagement.js'
export default {
  components: {
    addBox
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '优惠券名称',
          key: 'couponName'
        },
        {
          title: '优惠券面值',
          key: 'couponValue',
          width: 120,
        },
        {
          title: '优惠券类型',
          key: 'couponTypeName',
          width: 120,
        },
        {
          title: '使用日期',
          key: 'days',
          slot: 'days',
          width: 160,
        },
        {
          title: '使用门槛',
          key: 'minimumAmount',
          width: 150,
        },
        {
          title: '数量',
          key: 'restrictCouponNum',
          slot: 'restrictCouponNum',
          width: 80
        },
        {
          title: '操作',
          slot: 'action',
          width: 120
        }
      ],
      dataList: [],
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      size: 20,
      titleName: '添加优惠劵',
      modelShow: false,
      currentData: {},
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    add() {
      this.titleName = '添加优惠劵'
      this.modelShow = true
    },
    findPageDataList() {
      getCouponList({ size: this.size, pageIndex: this.current }).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    save() {
      let params = this.$refs.modelShow.formData
      addCoupon(params).then(res => {
        if (res.success) {
          this.$Message.success('保存成功！')
          this.current = 1
          this.findPageDataList()
          this.modelShow = false
        }
      })
    },
    editDetial(row) {
      this.titleName = '编辑优惠劵'
      row.date = [row.startDate || '', row.endDate || '']
      this.modelShow = true
      this.currentData = JSON.parse(JSON.stringify(row))
    },
    del(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          delCoupon({ id: row.id }).then(res => {
            if (res.success) {
              this.$Message.success('删除成功！')
              this.findPageDataList()
            }
          });
        },
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
</style>