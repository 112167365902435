<template>
  <div>
    <Form ref="formInline" :model="formData" :rules="ruleData" :label-width="180">
      <FormItem label="优惠劵名称：">
        <Input type="text" placeholder="请输入优惠劵名称" v-model="formData.couponName" style="width:400px"></Input>
      </FormItem>

      <FormItem label="优惠劵面值：">
        <Input type="number" v-model="formData.couponValue" style="width:400px"></Input>
      </FormItem>
      <FormItem label="优惠劵类型：">
        <Select v-model="formData.couponType" style="width:400px">
          <Option label="折扣券" value="1"></Option>
          <Option label="抵扣券" value="2"></Option>
          <Option label="免单券" value="3"></Option>
        </Select>
      </FormItem>
      <FormItem label="使用时间：">
        <RadioGroup v-model="formData.restrictDateType">
          <Radio label="1">天数</Radio>
          <Radio label="2">时间段</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="领取后多少天内有效：" v-if="formData.restrictDateType == 1">
        <Input type="number" v-model="formData.days" placeholder style="width:400px"></Input>
      </FormItem>
      <FormItem label="使用日期：" v-if="formData.restrictDateType ==2">
        <DatePicker
          v-model="formData.date"
          format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          placement="bottom-end"
          placeholder="起止日期"
          style="width: 400px"
          @on-change="handleDateChange"
        ></DatePicker>
      </FormItem>
      <!-- <FormItem label="使用门槛：">
        <RadioGroup v-model="formData.minimumAmount">
          <Radio :label="0">无门槛</Radio>
          <Radio :label="1">有门槛</Radio>
        </RadioGroup>
      </FormItem>-->
      <FormItem label="最低消费金额：">
        <Input
          type="number"
          v-model="formData.minimumAmount"
          placeholder="填写优惠券的最低消费金额"
          style="width:400px"
        ></Input>
      </FormItem>
      <!-- <FormItem label="优惠券发布数量：">
        <RadioGroup v-model="formData.couponIsLimited">
          <Radio :label="0">限量</Radio>
          <Radio :label="1">不限量</Radio>
        </RadioGroup>
      </FormItem>-->
      <FormItem label="优惠券的发布数量：">
        <Input
          type="number"
          v-model="formData.restrictCouponNum"
          placeholder="请填写优惠券的发布数量(0-不限制 >0 为限制数量)"
          style="width:400px"
        ></Input>
      </FormItem>
      <!-- <FormItem label="数量：">
        <Input type="number" placeholder="优惠劵总数" v-model="formData.num" style="width:400px"></Input>
      </FormItem>-->
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    titleName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        couponName: "",
        couponValue: 0,
        couponType: '',
        restrictDateType: '1',
        date: [],
        days: 0,
        // isUseSill: 0,
        restrictCouponNum: 1,
        num: 0
      },
      ruleData: {}
    }
  },
  created() {
    if (this.titleName != '添加优惠劵') {
      this.formData = this.currentData
    }
  },
  methods: {
    handleDateChange(date) {
      this.formData.startDate = date[0]
      this.formData.endDate = date[1]
    },
  }

}
</script>

<style lang='scss' scoped>
</style>